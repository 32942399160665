const normalizeArgs = (args: Record<string, unknown>) =>
  Object.keys(args)
    .sort()
    .reduce<Record<string, unknown>>((acc, k) => {
      if (args[k] != null && args[k] !== '') {
        acc[k] = args[k]
      }
      return acc
    }, {})

const removeKeyFromObject = (obj: unknown) => {
  if (typeof obj === 'object' && obj !== null && 'key' in obj) {
    const { key: theKey, ...rest } = obj
    return rest
  }
  return obj
}

export const buildCacheKey = <T extends object>(
  key: string,
  requiredArgs: Record<string, unknown> = {},
  optionalArgs: Record<string, unknown> = {},
  stripKey = false,
  typeGuard?: (obj: unknown) => obj is T
): T | null => {
  const hasInvalidRequiredArgs = Object.values(requiredArgs).some((value) => value === undefined || value === null)

  if (hasInvalidRequiredArgs) {
    return null
  }

  const normalizedRequired = normalizeArgs(requiredArgs)
  const normalizedOptional = normalizeArgs(optionalArgs)
  const result = { key, ...normalizedRequired, ...normalizedOptional } as T

  if (typeGuard) {
    const strippedResult = stripKey ? removeKeyFromObject(result) : result
    return typeGuard(strippedResult) ? strippedResult : null
  }

  return stripKey ? (removeKeyFromObject(result) as T) : (result as T)
}
