import useSWR from 'swr'
import { useCallback } from 'react'
import { createMyBookListItem, deleteMyBookListItem, getMyBookLists } from './api'
import { buildCacheKey } from '../../utils/cache'

export const useYourSystemLists = () => {
  const cacheKey = buildCacheKey('useYourSystemLists', {})

  const {
    data: lists,
    isLoading,
    error,
    mutate: dispatchListsUpdate
  } = useSWR(cacheKey, () => getMyBookLists(), {
    revalidateOnFocus: false,
    keepPreviousData: true
  })

  const addItem = useCallback(
    async (type: 'haveRead' | 'reading' | 'notReadYet', book: { id: number } | number) => {
      if (!type) return

      const bookId = typeof book === 'object' ? book.id : book

      await dispatchListsUpdate(
        async () => {
          await createMyBookListItem({ type, bookId })
          return lists
        },
        { rollbackOnError: true }
      )
    },
    [dispatchListsUpdate, lists]
  )

  const removeItem = useCallback(
    async (type: 'haveRead' | 'reading' | 'notReadYet', book: { id: number } | number) => {
      if (!type) return

      const bookId = typeof book === 'object' ? book.id : book

      await dispatchListsUpdate(
        async () => {
          await deleteMyBookListItem({ bookId })
          return lists
        },
        { rollbackOnError: true }
      )
    },
    [dispatchListsUpdate, lists]
  )

  return {
    data: lists,
    isLoading,
    error,
    addItem,
    removeItem,
    hasItem: (list: 'haveRead' | 'reading' | 'notReadYet', bookId: number) =>
      lists?.[list]?.books?.some((book: any) => book.id === bookId)
  }
}
