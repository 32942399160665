import useSWR from 'swr'
import { useCallback } from 'react'
import { createMyBookListItem, deleteMyBookListItem, getMyBookLists } from './api'
import { buildCacheKey } from '../../utils/cache'

export const useYourSystemList = ({ listType }: { listType: 'haveRead' | 'reading' | 'notReadYet' }) => {
  const cacheKey = buildCacheKey('useYourSystemList', { listType })

  const { data: lists, isLoading, mutate: dispatchListsUpdate } = useSWR(cacheKey, () => getMyBookLists())

  const addItem = useCallback(
    async (book: { id: number } | number) => {
      if (!listType) return

      const bookId = typeof book === 'object' ? book.id : book

      await dispatchListsUpdate(
        async () => {
          await createMyBookListItem({ type: listType, bookId })
          return lists
        },
        { rollbackOnError: true }
      )
    },
    [dispatchListsUpdate, lists]
  )

  const removeItem = useCallback(
    async (book: { id: number } | number) => {
      if (!listType) return

      const bookId = typeof book === 'object' ? book.id : book

      await dispatchListsUpdate(
        async () => {
          await deleteMyBookListItem({ bookId })
          return lists
        },
        { rollbackOnError: true }
      )
    },
    [dispatchListsUpdate, lists]
  )

  const systemList = lists?.[listType]

  return {
    data: systemList,
    isLoading,
    addItem,
    removeItem,
    hasItem: (bookId: number) => lists?.[listType]?.books?.some((book: any) => book.id === bookId)
  }
}
