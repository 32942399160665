import { useCallback } from 'react'
import useSWR from 'swr'
import { buildCacheKey } from '../../utils/cache'
import { getListingByUuid, ListingCondition, removeListingByUuid, updateListingByUuid } from './api'
import { useYourListings } from './use-your-listings'

export const useYourListing = ({ listingUuid }: { listingUuid: string }) => {
  if (!listingUuid) throw new Error('listingUuid is required')

  const cacheKey = buildCacheKey('useYourListing', { listingUuid })

  const { data, isLoading, mutate: dispatchListingUpdate } = useSWR(cacheKey, () => getListingByUuid({ listingUuid }))

  const { refresh: refreshListingsList } = useYourListings({ listingStatus: ['available'] })

  const updateItem = useCallback(
    async (body: {
      price?: number
      condition?: (typeof ListingCondition)[keyof typeof ListingCondition]
      comment?: string
    }) => {
      if (!listingUuid) return

      await dispatchListingUpdate(
        async () => {
          await updateListingByUuid({ listingUuid, body })
          return data
        },
        { rollbackOnError: true }
      )

      refreshListingsList()
    },
    [dispatchListingUpdate, data, listingUuid, refreshListingsList]
  )

  const removeItem = useCallback(async () => {
    if (!listingUuid) return

    await removeListingByUuid({ listingUuid })

    refreshListingsList()
  }, [listingUuid, refreshListingsList])

  return {
    data,
    isLoading,
    updateItem,
    removeItem
  }
}
